import * as React from 'react';
import PropTypes from 'prop-types';
import style from './style.styl';
import l10n from '../../utils/l10n';
import Checkbox from '../Checkbox';
import AppConfig, { connectAppConfig } from 'src/shared/components/AppConfig';

class ConfirmCheckbox extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
  };

  static contextTypes = {
    store:  PropTypes.shape(),
    router: PropTypes.shape(),
    form:   PropTypes.any,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      checked: false,
    };
    this.onChange = this.onChange.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    if (this.context.form) {
      this.context.form.addInput(this);
    }
  }

  onChange(e) {
    const { checked } = e.target;
    this.setState({ checked }, this.validate);
  }

  validate() {
    const errors = [];
    if (!this.state.checked) errors.push('confirm_required');
    this.setState({ errors });
    return errors;
  }

  renderLink(type, text) {
    const { config } = this.props;
    return config[type]
      ? <a href={config[type]} target="_blank">{text}</a>
      : text;
  }

  render() {
    const { type } = this.props;
    const { checked, errors } = this.state;
    return (
      <label htmlFor="order-agree" className={style.agree}>
        <Checkbox id="order-agree" onChange={this.onChange} checked={checked} />
        <div className={style.agree_text}>
          Нажимая кнопку «{type}», Пользователь безоговорочно соглашается с условиями{' '}
          {this.renderLink('TERMS', 'Пользовательского соглашения ')}
          и подтверждает, что ознакомился с{' '}
          {this.renderLink('POLICY', 'Политикой конфиденциальности')}.
          {errors && errors.length
            ? (
              <div className={style.errors}>
                {errors.map(error => <div key={error} className={style.error}>{l10n(error)}</div>)}
              </div>
            )
            : null
          }
        </div>
      </label>
    );
  }
}

export default connectAppConfig(ConfirmCheckbox);
