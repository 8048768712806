import React from 'react';
import PropTypes from 'prop-types';
import style from './style';

export default function Title(props) {
  const className = [props.className, style.header].join(' ');
  return (
    <h1 {...props} className={className}>{props.children}</h1>
  );
}

Title.propTypes = {
  className: PropTypes.string,
  children:  PropTypes.node,
};

