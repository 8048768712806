import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from './style.styl';
import isEmpty from '../../../utils/isEmpty';

class Form extends Component {
  static propTypes = {
    fields:   PropTypes.arrayOf(PropTypes.shape({
      name:       PropTypes.string,
      label:      PropTypes.string,
      type:       PropTypes.string,
      validators: PropTypes.array,
    })),
    onSubmit: PropTypes.func,
    children: PropTypes.node,
  };

  static childContextTypes = {
    form: PropTypes.any,
  };

  static defaultProps = {
    fields: [],
  };

  static childrenValues(children) {
    const values = {};
    for (const i in children) {
      if ({}.hasOwnProperty.call(children, i)) {
        values[i] = children[i].state.value;
      }
    }
    return values;
  }

  static validateChilds(children) {
    const errors = {};
    for (const i in children) {
      if ({}.hasOwnProperty.call(children, i)) {
        const child = children[i];
        const error = child.validate();
        if (error.length) errors[i] = error;
      }
    }
    return errors;
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      errors: {},
      // fields: {},
    };

    this.fields = {};
    this.submit = this.submit.bind(this);
    this.setErrors = this.setErrors.bind(this);
  }

  getChildContext() {
    return {
      form: this,
    };
  }

  setErrors(resp) {
    if (resp && resp.error && resp.error.error) {
      const errors = resp.error.error;
      for (const name in errors) {
        if ({}.hasOwnProperty.call(errors, name)) {
          if (this.fields[name]) this.fields[name].setErrors(errors[name]);
        }
      }
    }
  }

  submit(e) {
    e.preventDefault();

    const errors = this.constructor.validateChilds(this.fields);
    if (!isEmpty(errors)) {
      this.setState({ errors });
      return false;
    }

    if (typeof this.props.onSubmit === 'function') {
      const resp = this.props.onSubmit(this.constructor.childrenValues(this.fields));
      if (resp && typeof resp.then === 'function') {
        resp.then(this.setErrors, this.setErrors);
      } else if (resp && resp.errors) {
        this.setState({ errors: resp.errors });
      }
    }
    return false;
  }

  addInput(input) {
    const { name } = input.props;
    if (name) this.fields[name] = input;
  }

  removeInput(input) {
    const { name } = input.props;
    if (name) delete this.fields[name];
  }

  render() {
    const { children } = this.props;
    const { errors } = this.state;

    const commonErrors = errors.common
      ? (
        <div className={style.errorBlock}>
          {errors.common.map(error => <div key={error} className={style.error}>{error}</div>)}
        </div>
      ) : null;

    return (
      <form method="POST" onSubmit={this.submit} noValidate>
        {commonErrors}
        {children}
      </form>
    );
  }
}

export default Form;
