import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './style.styl';
import { EMAIL_REG_EXP, PHONE_REG_EXP } from 'src/constants';
import * as API from '../../../utils/API';

import Meta from '../../../components/Meta';
import Button from '../../components/Button';
import Form from '../../components/Form';
import Input from '../../components/Input';
import { connectAppConfig } from 'src/shared/components/AppConfig';

function Bullet(props) {
  return (
    <div className={style.bullet}>
      <h3>{props.title}</h3>
      <p>{props.text}</p>
    </div>
  );
}

Bullet.propTypes = {
  title: PropTypes.string,
  text:  PropTypes.string,
};

function emptyValidator(field) {
  return (value) => { return value.length ? null : [`Введите ${field}`]; };
}

const formFields = [
  {
    name:       'name',
    label:      'Имя и фамилия представителя',
    validators: [emptyValidator('имя')],
  },
  {
    name:       'text',
    label:      'Название компании',
    validators: [emptyValidator('название компании')],
  },
  {
    name:       'email',
    label:      'Эл.почта',
    validators: [
      emptyValidator('эл.почту'),
      (value) => { return !value || EMAIL_REG_EXP.test(value) ? null : ['Неправильный формат']; },
    ],
  },
  {
    name:       'phone',
    type:       'phone',
    label:      'Телефон',
    validators: [
      emptyValidator('номер телефона'),
      (value) => { return !value || PHONE_REG_EXP.test(value) ? null : ['Введите номер телефона']; },
    ],
  },
];

class PartnersPageContainer extends Component {
  static contextTypes = {
    store: PropTypes.shape(),
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.send = this.send.bind(this);
  }

  send(form) {
    if (this.state.loading) return false;
    this.setState({ loading: true });
    return this.props.requester.send(API.partnerForm(form))
      .then((resp) => {
        this.setState({ loading: false, sended: true });
        return resp;
      }, (resp) => {
        // if (resp.error.captcha) {
        //   this.setState({
        //     captcha: resp.error.captcha,
        //   });
        // } else {
        //   this.setState({ captcha: null });
        // }
        this.setState({ loading: false });
        return resp;
      });
  }

  render() {
    const title = 'Партнерам';
    return (
      <div className={style.page}>
        <Meta title={title} />
        <h1>{title}</h1>
        <h2>Интеграция</h2>
        <div className={style.cols}>
          <div className={style.text}>
            <p>
              AlfaDetali предлагает бизнесу автомобильных запчастей все что нужно в одной системе: продажи, закупки,
              склад, финансы, клиенты и поставщики.
            </p>
            <p>
              Вы можете интегрировать ваше программное обеспечение с нашей торговой площадкой. Процесс интеграции
              подробно описан в подготовленной технической документации.
            </p>
            <div className={style.bullets}>
              <Bullet title="Быстро" text="Интеграция занимает всего несколько часов" />
              <Bullet title="Просто" text="Ваши сотрудники освоят систему за 1 день" />
              <Bullet title="Доступно" text="Интеграция вашего ПО в нашу систему бесплатна" />
              <Bullet title="Всегда онлайн" text="Доступ с любого устройства, подключенного к интернету" />
            </div>
          </div>
          <div className={style.image}>
            <div className={style.slide1} />
          </div>
        </div>
        <div className={style.download}>
          <Button tag="a" to="https://static.alfadetali.ru/docs/doc1.docx" bold filled>Скачать документацию</Button>
        </div>
        <h2>Подключение «под ключ»</h2>
        <div className={style.cols}>
          <div className={style.text}>
            <p>
              AldaDetali предлагает систему программного обеспечения, специально разработанную для ведения бизнеса в
              сфере покупки/продажи/поставки автомобильных запчастей.
            </p>
            <p>Заполните форму обратной связи и мы предоставим вам доступ к демоверсии системы AlfaDetali.</p>
            <div className={style.bullets}>
              <Bullet title="Реклама" text="Интеграция с рекламными площадками Яндекс, Авито" />
              <Bullet title="Каталоги" text="Подробные каталоги от производителей" />
              <Bullet title="Поиск" text="Интеллектуальный поиск запчастей" />
              <Bullet title="Технологии" text="Система разработана по современным стандартам" />
            </div>
          </div>
          <div className={style.image}>
            <div className={style.slide2} />
          </div>
        </div>
        <div className={style.cols}>
          <div className={style.text}>
            <div className={style.form}>
              {this.state.sended
                ? <p>Спасибо, ваша заявка принята.</p>
                : (
                  <Form onSubmit={this.send}>
                    {formFields.map(field => <Input key={field.name} {...field} />)}
                    <Button name="register" loading={this.state.loading} filled bold block>Отправить заявку</Button>
                  </Form>
                )
              }
            </div>
          </div>
          <div className={style.image}>
            <div className={style.contacts}>
              <div className={style.phone}>+7 (812) 676-15-66</div>
              <div className={style.cta}>
                Проведем демонстрацию возможностей AlfaDetali
                Ответим на любые вопросы
              </div>
              <div><a className={style.email} href="mailto:partners@alfadetali.ru">partners@alfadetali.ru</a></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connectAppConfig(connect()(PartnersPageContainer));
