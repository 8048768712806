import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from './style';

class PartParams extends Component {
  static propTypes = {
    params: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any,
      }),
    ])).isRequired,
  };

  static generateParam(param, i) {
    if (!param) return null;
    return (
      <div key={i} className={style.param}>
        <div className={style.label}>{param.label}</div>
        <div className={style.value}>{param.value}</div>
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.generateParam = this.constructor.generateParam.bind(this);
  }

  render() {
    return (
      <div className={style.params}>
        {this.props.params.map(this.generateParam)}
      </div>
    );
  }
}

export default PartParams;
