import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import style from './style';
import * as API from '../../../utils/API';
import * as propTypes from '../../../propTypes';

import Meta from '../../../components/Meta';
import Form from '../../components/Form';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Captcha from '../../components/Captcha';
import Title from '../../components/Title';
import { connectAppConfig } from 'src/shared/components/AppConfig';

class ResetPageContainer extends Component {
  static propTypes = {
    profile: PropTypes.shape,
    history: propTypes.history,
    query:   propTypes.query,
    params:  PropTypes.shape({
      code:  PropTypes.string,
      token: PropTypes.string,
    }),
  };

  static contextTypes = {
    store:  PropTypes.shape(),
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
      captcha: null,
    };

    this.change = this.change.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.profile !== this.props.profile) {
      const redirect = this.props.query.redirect || '/';
      this.props.history.push(redirect);
    }
  }

  change(fields) {
    if (this.state.loading) return false;
    this.setState({ loading: true });
    return this.props.requester.send(API.changePassword(fields.password, this.props.params.code))
      .then((resp) => {
        this.setState({ loading: false, sended: true });
        return resp;
      }, (resp) => {
        if (resp.error.captcha) {
          this.setState({
            captcha: resp.error.captcha,
          });
        } else {
          this.setState({ captcha: null });
        }
        if (resp.error && resp.error.error && resp.error.error.token) {
          this.setState({ showErrorMessage: true });
        }

        this.setState({ loading: false });
        return resp;
      });
  }

  render() {
    if (this.state.sended) {
      return (
        <div className={style.wrapper}>
          <div className={style.form}>
            <p>Пароль успешно сменен. Перейдите на страницу <Link to="/login">авторизации</Link>, чтобы войти на сайт.
            </p>
          </div>
        </div>
      );
    }
    const title = 'Смена пароля';

    return (
      <div>
        <Meta title={title} />
        <Title>{title}</Title>
        <div className={style.wrapper}>
          <div className={style.form}>
            <Form onSubmit={this.change}>
              <div className={style.input}>
                <Input
                  ref={(c) => { this.password = c; }}
                  label="Пароль"
                  name="password"
                  type="password"
                  validators={[(value) => {
                    return value && value.length >= 6 ? null : ['Пароль должен быть не менее 6 символов'];
                  }]}
                />
              </div>
              <div className={style.input}>
                <Input
                  label="Подтверждение пароля"
                  name="password_repeat"
                  type="password"
                  validators={[(value) => {
                    return value === this.password.state.value ? null : ['Пароли не совпадают'];
                  }]}
                />
              </div>
              {this.state.captcha ? <Captcha img={this.state.captcha} /> : null}
              {this.state.showErrorMessage ? <p>Неправильный код</p> : null}
              <Button name="reset" loading={this.state.loading} filled bold>Сменить</Button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile,
});
export default connectAppConfig(connect(mapStateToProps)(ResetPageContainer));
