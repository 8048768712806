import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as API from '../../../utils/API';
import style from './style.styl';
import formatDate from '../../../utils/formatDate';
import * as propTypes from '../../../propTypes';

import Meta from '../../../components/Meta';
import Form from '../../components/Form';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Captcha from '../../components/Captcha';
import PartParams from '../../components/PartParams';
import Price from '../../../components/Price';
import Title from '../../components/Title';
import { connectAppConfig } from 'src/shared/components/AppConfig';

class CheckOrderPageContainer extends Component {
  static propTypes = {
    profile: propTypes.profile,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
    };

    this.check = this.check.bind(this);
  }

  componentDidMount() {
    this.props.requester.send(API.captcha('order_status')).then((res) => {
      if (res.captcha) {
        this.setState({ captcha: res.captcha });
      }
    });
  }

  check(fields) {
    if (this.state.loading) return false;
    this.setState({ loading: true });
    return this.props.requester.send(API.orderStatus(fields.orderId, fields.captcha))
      .then((resp) => {
        this.setState({
          order:   resp,
          loading: false,
          captcha: null,
        });
        return null;
      }, (resp) => {
        if (resp.error.captcha) {
          this.setState({
            captcha: resp.error.captcha,
          });
        } else {
          this.setState({ captcha: null });
        }
        this.setState({ loading: false });
        return resp;
      });
  }

  order() {
    const { order } = this.state;
    const params = [
      { label: 'Способ получения:', value: order.deliveryType ? order.deliveryType.title : '-' },
      { label: 'Место получения:', value: order.deliveryAddres },
      { label: 'Сумма заказа:', value: <Price value={order.summ} /> },
      { label: 'Статус:', value: order.status.title },
    ];

    return (
      <div className={style.order}>
        <h2 className={style.header}>Заказ №{order.id} от {formatDate(order.created)}</h2>
        <PartParams params={params} />
      </div>
    );
  }

  render() {
    const title = 'Что с моим заказом?';
    return (
      <div>
        <Meta title={title} />
        <Title>{title}</Title>
        <div className={style.wrapper}>
          <div className={style.form}>
            <Form onSubmit={this.check}>
              <div className={style.input}>
                <Input
                  label="Введите номер заказа"
                  name="orderId"
                  validators={[(value) => { return !value || Number.isNaN(+value) ? ['Введите номер заказа'] : null; }]}
                />
              </div>
              {this.state.captcha ? <Captcha img={this.state.captcha} /> : null}
              <Button name="check-order" loading={this.state.loading} filled bold>Проверить статус</Button>
            </Form>
          </div>
        </div>
        {this.state.order ? this.order() : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile,
});
export default connectAppConfig(connect(mapStateToProps)(CheckOrderPageContainer));
