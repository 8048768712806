import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import style from './style';
import * as API from '../../../utils/API';
import { setUser } from '../../../redux/api/actions';
import { EMAIL_REG_EXP } from 'src/constants';
import * as propTypes from '../../../propTypes';

import Meta from '../../../components/Meta';
import Form from '../../components/Form';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Captcha from '../../components/Captcha';
import Title from '../../components/Title';
import { connectAppConfig } from 'src/shared/components/AppConfig';

class LoginPageContainer extends Component {
  static propTypes = {
    location: propTypes.location,
    history:  propTypes.history,
    profile:  propTypes.profile,
    dispatch: PropTypes.func,
    query:    propTypes.query,
  };

  static contextTypes = {
    store: PropTypes.shape(),
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      loading:           false,
      showResendMessage: false,
      resendLoading:     false,
      resendResult:      '',
    };

    this.login = this.login.bind(this);
    this.resend = this.resend.bind(this);
    this.onResponse = this.onResponse.bind(this);
  }

  componentDidMount() {
    this.props.requester.send(API.captcha('login')).then((res) => {
      if (res.captcha) {
        this.setState({ captcha: res.captcha });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.profile !== this.props.profile) {
      const redirect = this.props.query.redirect || '/';
      this.props.history.push(redirect);
    }
  }

  onResponse(resp) {
    this.setState({ loading: false });
    return resp;
  }

  login(fields) {
    if (this.state.loading) return false;
    this.setState({ loading: true });
    return this.props.requester.send(API.login(fields))
      .then((resp) => {
        this.props.dispatch(setUser(resp));
        return null;
      }, (resp) => {
        if (resp.error.captcha) {
          this.setState({
            captcha: resp.error.captcha,
          });
        } else {
          this.setState({ captcha: null });
        }
        if (
          resp.error && resp.error.error && resp.error.error.username &&
          resp.error.error.username.indexOf('user_not_confirmed') !== -1
        ) {
          this.setState({ showResendMessage: true });
        }

        this.setState({ loading: false });
        return resp;
      });
  }

  resend(e) {
    e.preventDefault();
    const email = this.email.state.value;
    this.setState({ resendLoading: true });
    this.props.requester.send(API.resend({ email, redirect_url: window.location.origin + /activate/ })).then((resp) => {
      this.setState({ resendLoading: false });
      if (resp && resp.status === 'ok') {
        this.setState({ resendResult: 'Письмо отправлено' });
      }
    }, () => this.setState({ resendLoading: false, resendResult: 'Ошибка' }));
  }

  render() {
    const title = 'Вход';

    return (
      <div>
        <Meta title={title} />
        <Title>{title}</Title>
        <div className={style.wrapper}>
          <div className={style.form}>
            {this.state.showResendMessage ? (
              <div className={style.resend}>
                <Button name="login-resend" loading={this.state.resendLoading} onClick={this.resend} ghost size="sm">
                  Отправить сообщение повторно
                </Button>
                {this.state.resendResult}
              </div>
            ) : null
            }
            <Form onSubmit={this.login}>
              <div className={style.input}>
                <Input
                  ref={(c) => { this.email = c; }}
                  label="Эл.почта"
                  name="username"
                  type="email"
                  validators={[(value) => { return value && EMAIL_REG_EXP.test(value) ? null : ['Введите эл. почту']; }]}
                />
              </div>
              <div className={style.input}>
                <Input
                  label="Пароль"
                  name="password"
                  type="password"
                  validators={[(value) => { return value && value.length ? null : ['Введите пароль']; }]}
                />
              </div>
              {this.state.captcha ? <Captcha img={this.state.captcha} /> : null}
              <Button name="login" loading={this.state.loading} filled bold>Войти</Button>
              <Link className={style.link} to="/forgot">Забыли пароль?</Link>
              <Link className={style.link} to="/register">Регистрация</Link>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile,
});
export default connectAppConfig(connect(mapStateToProps)(LoginPageContainer));
