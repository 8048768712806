import React from 'react';
import style from './style.styl';

import Icon from '../Icon';

export default function Checkbox(props) {
  return (
    <label className={style.label}>
      <input className={style.checkbox} type="checkbox" {...props} />
      <Icon className={style.pseudo} glyph="checkbox" />
    </label>
  );
}

Checkbox.styles = style;
