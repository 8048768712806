import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import style from './style';
import * as API from '../../../utils/API';
import { EMAIL_REG_EXP, PHONE_REG_EXP } from 'src/constants';
import * as propTypes from '../../../propTypes';

import Meta from '../../../components/Meta';
import Form from '../../components/Form';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Captcha from '../../components/Captcha';
import Title from '../../components/Title';
import ConfirmCheckbox from '../../../components/ConfirmCheckbox';
import { connectAppConfig } from 'src/shared/components/AppConfig';

class RegisterPageContainer extends Component {
  static propTypes = {
    location: propTypes.location,
    profile:  propTypes.profile,
    history:  propTypes.history,
    query:    propTypes.query,
  };

  static contextTypes = {
    store: PropTypes.shape(),
  };

  static renderDone() {
    return (
      <div className={style.wrapper}>
        <div className={style.form}>
          <p>На ваш почтовый ящик было отправлено письмо со ссылкой на подтверждение учетной записи.</p>
        </div>
      </div>
    );
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
      sended:  false,
    };

    this.register = this.register.bind(this);
  }

  componentDidMount() {
    this.props.requester.send(API.captcha('registration')).then((res) => {
      if (res.captcha) {
        this.setState({ captcha: res.captcha });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.profile !== this.props.profile) {
      const redirect = this.props.query.redirect || '/';
      this.props.history.push(redirect);
    }
  }

  register(user) {
    if (this.state.loading) return false;
    this.setState({ loading: true });
    return this.props.requester.send(API.register({ ...user, redirect_url: window.location.origin + /activate/ }))
      .then((resp) => {
        this.setState({ loading: false, sended: true });
        return resp;
      }, (resp) => {
        if (resp.error.captcha) {
          this.setState({
            captcha: resp.error.captcha,
          });
        } else {
          this.setState({ captcha: null });
        }
        this.setState({ loading: false });
        return resp;
      });
  }

  render() {
    const { sended, captcha, loading } = this.state;
    if (sended) return this.constructor.renderDone();

    const title = 'Регистрация';

    return (
      <div>
        <Meta title={title} />
        <Title>{title}</Title>
        <div className={style.wrapper}>
          <div className={style.form}>
            <Form onSubmit={this.register}>
              <div className={style.input}>
                <Input
                  name="username"
                  label="Эл.почта*"
                  validators={[(value) => {
                    return EMAIL_REG_EXP.test(value) ? null : ['Неправильный формат'];
                  }]}
                />
              </div>
              <div className={style.input}>
                <Input
                  name="password"
                  label="Пароль*"
                  type="password"
                  validators={[(value) => {
                    return value && value.length >= 6 ? null : ['Пароль должен быть не короче 6 символов'];
                  }]}
                />
              </div>
              <div className={style.input}>
                <Input name="name" label="Имя" />
              </div>
              <div className={style.input}>
                <Input
                  name="phone"
                  type="phone"
                  label="Телефон"
                  validators={[(value) => {
                    return !value || PHONE_REG_EXP.test(value) ? null : ['Введите номер телефона'];
                  }]}
                />
              </div>
              <ConfirmCheckbox name="confirm" type="Регистрация" />
              {captcha && <Captcha img={captcha} />}
              <Button name="register" loading={loading} filled bold>Регистрация</Button>
              <Link className={style.link} to="/login">Вход</Link>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile,
});
export default connectAppConfig(connect(mapStateToProps)(RegisterPageContainer));
