import React from 'react';
import PropTypes from 'prop-types';
import style from './style';

import Input from '../../components/Input';

export default function Captcha(props) {
  if (!props.img) return <div />;

  return (
    <div>
      <div className={style.captcha}>
        <div className={style.captcha_input}>
          <Input
            name="captcha"
            key="captcha"
            label="Код с изображения"
            type="text"
            maxlength={5}
            validators={[(value) => { return value && value.length === 5 ? null : ['Введите код с картинки']; }]}
          />
        </div>
        <div className={style.captcha_img}>
          <img src={props.img} alt="captcha" />
        </div>
      </div>
    </div>
  );
}

Captcha.propTypes = {
  img: PropTypes.string,
};

