import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import style from './style';
import * as API from '../../../utils/API';
import * as propTypes from '../../../propTypes';
import { setUser } from '../../../redux/api/actions';

import Icon from '../../../components/Icon';
import { connectAppConfig } from 'src/shared/components/AppConfig';

const itemClick = (e, item) => {
  if (item.action) {
    e.preventDefault();
    item.action();
  }
};

const renderActiveItem = (item) => {
  return (
    <label htmlFor="cabinet-menu-toggler" className={classnames(style.item, style.active)}>
      <Icon glyph="arrow_down" className={style.icon} />
      {item.title}
    </label>
  );
};

class CabinetMenu extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    current:  PropTypes.string,
    open:     PropTypes.bool,
    history:  propTypes.history,
  };

  static defaultProps = {
    open: false,
  };

  static contextTypes = {
    router: PropTypes.shape(),
  };

  constructor(props, context) {
    super(props, context);

    this.logoutAction = this.logoutAction.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  logoutAction() {
    this.props.requester.send(API.login()).then((res) => {
      this.props.dispatch(setUser(res));
      this.props.history.push('/');
    });
  }

  renderItem(item) {
    if (!this.props.open && item.id === this.props.current) return null;
    const className = classnames(style.item, this.props.open && style.item_open);
    if (!item.link) {
      return (
        <div onClick={e => itemClick(e, item)} key={item.id} className={className}>
          {item.title}
        </div>
      );
    }
    return (
      <Link to={item.link} onClick={e => itemClick(e, item)} key={item.id} className={className}>
        {item.title}
      </Link>
    );
  }

  render() {
    const items = [
      { id: 'profile', title: 'Профиль', link: '/cabinet' },
      { id: 'orders', title: 'Заказы', link: '/cabinet/orders' },
      { id: 'exit', title: 'Выход', action: this.logoutAction },
    ];

    const activeItem = items.find(item => item.id === this.props.current);

    return (
      <div className={style.items}>
        {!this.props.open && renderActiveItem(activeItem)}
        <input id="cabinet-menu-toggler" className={style.toggler} type="checkbox" />
        <div className={classnames(style.menu, this.props.open && style.open)}>
          {items.map(this.renderItem)}
        </div>
      </div>
    );
  }
}

export default withRouter(connectAppConfig(connect()(CabinetMenu)));
