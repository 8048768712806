import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './style';
import * as API from '../../../utils/API';
import { PHONE_REG_EXP } from 'src/constants';
import * as propTypes from '../../../propTypes';

import Meta from '../../../components/Meta';
import Form from '../../components/Form';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Title from '../../components/Title';
import { connectAppConfig } from 'src/shared/components/AppConfig';

class ProfilePageContainer extends Component {
  static propTypes = {
    location: propTypes.location,
    query:    propTypes.query,
    history:  propTypes.history,
    profile:  propTypes.profile,
  };

  static contextTypes = {
    store: PropTypes.shape(),
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
      saved:   false,
    };

    this.updateProfile = this.updateProfile.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.profile) {
      const redirect = this.props.query.redirect || '/';
      this.props.history.push(redirect);
    }
  }

  updateProfile(fields) {
    if (this.state.loading) return;
    this.setState({ loading: true, saved: false });
    this.props.requester.send(API.updateProfile(fields))
      .then(() => {
        this.setState({ loading: false, saved: true });
      });
  }

  render() {
    if (!this.props.profile) return <div />;

    const title = 'Личный кабинет';

    return (
      <div>
        <Meta title={title} />
        <Title>{title}</Title>
        <div className={style.wrapper}>
          <div className={style.form}>
            <Form onSubmit={this.updateProfile}>
              <div className={style.input}>
                <Input
                  name="phone"
                  label="Телефон"
                  type="phone"
                  value={this.props.profile.phone}
                  validators={[(value) => {
                    return value && !PHONE_REG_EXP.test(value) ? ['Неправильный формат'] : null;
                  }]}
                />
              </div>
              <div className={style.input}>
                <Input name="name" label="Имя" value={this.props.profile.name} />
              </div>
              {/* <div className={style.input}> */}
              {/* <Input name="address" label="Адрес" value={this.props.profile.address} /> */}
              {/* </div> */}
              <Button name="profile" loading={this.state.loading} filled bold>Сохранить</Button>
              {this.state.saved
                ? <span>Успешно сохранено</span>
                : null
              }
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile,
});
export default connectAppConfig(connect(mapStateToProps)(ProfilePageContainer));
