import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import style from './style';
import * as API from '../../../utils/API';
import { EMAIL_REG_EXP } from 'src/constants';
import * as propTypes from '../../../propTypes';

import Meta from '../../../components/Meta';
import Form from '../../components/Form';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Captcha from '../../components/Captcha';
import Title from '../../components/Title';
import { connectAppConfig } from 'src/shared/components/AppConfig';

class ForgotPageContainer extends Component {
  static propTypes = {
    location: propTypes.location,
    profile:  propTypes.profile,
    history:  propTypes.history,
    query:    propTypes.query,
  };

  static contextTypes = {
    store: PropTypes.shape(),
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
      captcha: null,
    };

    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    this.props.requester.send(API.captcha('password_reset')).then((res) => {
      if (res.captcha) {
        this.setState({ captcha: res.captcha });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.profile !== this.props.profile) {
      const redirect = this.props.query.redirect || '/';
      this.props.history.push(redirect);
    }
  }

  reset(fields) {
    if (this.state.loading) return false;
    this.setState({ loading: true });
    return this.props.requester.send(API.reset({ ...fields, redirect_url: window.location.origin + /reset/ }))
      .then((resp) => {
        this.setState({ loading: false, sended: true });
        return resp;
      }, (resp) => {
        if (resp.error.captcha) {
          this.setState({
            captcha: resp.error.captcha,
          });
        } else {
          this.setState({ captcha: null });
        }

        this.setState({ loading: false });
        return resp;
      });
  }

  render() {
    if (this.state.sended) {
      return (
        <div className={style.wrapper}>
          <div className={style.form}>
            <p>На ваш почтовый ящик было отправлено письмо со ссылкой на восстановление пароля.</p>
          </div>
        </div>
      );
    }

    const title = 'Восстановление пароля';

    return (
      <div>
        <Meta title={title} />
        <Title>{title}</Title>
        <Form onSubmit={this.reset}>
          <div className={style.input}>
            <Input
              label="Эл.почта"
              name="username"
              type="email"
              validators={[(value) => { return value && EMAIL_REG_EXP.test(value) ? null : ['Введите эл. почту']; }]}
            />
          </div>
          {this.state.captcha ? <Captcha img={this.state.captcha} /> : null}
          <Button name="forgot" loading={this.state.loading} filled bold block>Восстановить</Button>
          <div className={style.links}>
            <Link className={style.link} to="/login">Вход</Link>
            <Link className={style.link} to="/register">Регистрация</Link>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile,
});
export default connectAppConfig(connect(mapStateToProps)(ForgotPageContainer));
